import { Injectable } from '@angular/core';
import { AuthRes, GoogleSign, SignIn } from '@app/domain/interfaces/auth.interface';
import { HttpBaseService } from '@app/infrastructure/http/http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends HttpBaseService {
  private readonly baseUrl = 'auth';

  auth(): Observable<AuthRes> {
    return this.Get<AuthRes>(this.baseUrl);
  }

  login(body: SignIn): Observable<AuthRes> {
    return this.Post<AuthRes>(`${this.baseUrl}/login`, body);
  }

  googleLogin(params: GoogleSign): Observable<AuthRes> {
    return this.Post<AuthRes>(`${this.baseUrl}/google`, params);
  }
}
