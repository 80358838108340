import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { StateService } from '@app/application/services/state/state.service';
import { UtilsService } from '@app/shared/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  readonly router: Router = inject(Router);

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly stateSrv: StateService = inject(StateService);
  private readonly utilsSrv: UtilsService = inject(UtilsService);

  navigate(url: string[], extras: NavigationExtras = {}) {
    return this.router.navigate(['/', this.stateSrv.state.lang(), ...url], extras);
  }

  navigateWithoutLang(url: string[], extras: NavigationExtras = {}) {
    return this.router.navigate(['/', ...url], extras);
  }

  navigateByQueryParams(queryParams: { [key: string]: string } = {}) {
    return this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });
  }

  navigateToNewTab(url: string[]) {
    const path = this.router.createUrlTree(['/', this.stateSrv.state.lang(), ...url]).toString();

    this.utilsSrv.navigateToExternalUrl(path);
  }

  resetQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: '',
    });
  }
}
