import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '@app/application/services/state/state.service';
import { DEFAULT_LANG, LANGS_LIST } from '@app/core/constants/global.constants';
import { Cookie } from '@app/core/enums/app.enums';
import { StorageService } from '@app/core/services/storage/storage.service';
import { Lang } from '@app/core/types/lang.types';
import { UtilsService } from '@app/shared/services/utils/utils.service';
import { map, Observable } from 'rxjs';
import { TranslateService as NgxTranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly router: Router = inject(Router);
  private readonly location: Location = inject(Location);
  private readonly ngxTranslateSrv: NgxTranslateService = inject(NgxTranslateService);
  private readonly stateSrv: StateService = inject(StateService);
  private readonly utilsSrv: UtilsService = inject(UtilsService);
  private readonly storageSrv: StorageService = inject(StorageService);
  init() {
    const path = this.location.path();
    const langCodeFromUrl = path.split('/')[1] as any;
    const langFallback =
      this.storageSrv.getCookie(Cookie.Lang) || (this.ngxTranslateSrv.getBrowserLang() as any) || DEFAULT_LANG;
    const lang = LANGS_LIST.includes(langCodeFromUrl) ? langCodeFromUrl : langFallback;

    this.onLangChange(lang);
  }

  getCurrentLang(): Lang {
    return this.stateSrv.state.lang();
  }

  changeLanguage(lang: Lang): void {
    if (lang !== this.getCurrentLang()) {
      this.onLangChange(lang);
      this.changeLangRoute(lang);
    }
  }

  translate(key: string, params: { [key: string]: any } = {}): string {
    return this.ngxTranslateSrv.instant(key, params);
  }

  getRaw(path: string, lang: Lang = this.getCurrentLang()): Observable<{ [key: string]: any }> {
    return this.ngxTranslateSrv.getTranslation(lang).pipe(map((translations) => translations[path]));
  }

  private onLangChange(lang: Lang) {
    this.changeHTMLLangAttr(lang);
    this.ngxTranslateSrv.use(lang);
    this.stateSrv.update('lang', lang);
    this.storageSrv.setCookie(Cookie.Lang, lang);
  }

  private changeLangRoute(lang: Lang): void {
    const url = this.router.url;
    const urlSegments = url.split('/');

    if (urlSegments[1] !== lang) {
      urlSegments[1] = lang;
      const newUrl = urlSegments.join('/');

      if (newUrl !== url) {
        this.router.navigateByUrl(newUrl);
      }
    }
  }

  private changeHTMLLangAttr(lang: Lang): void {
    const { document, renderer } = this.utilsSrv;

    const htmlTag = document?.documentElement;
    htmlTag && renderer.setAttribute(htmlTag, 'lang', lang);
  }
}
