import { Injectable, TemplateRef, inject, signal } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavConfig } from '@app/presentation/interfaces/sidenav.interface';
import { DomService } from '../dom/dom.service';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private readonly domSrv = inject(DomService);

  tpl = signal<TemplateRef<any>>(null);
  status = signal<'opened' | 'closed'>(null as any);

  private cssClasses = signal<string[]>([]);
  private sidenav = signal<MatSidenav>(null as any);

  set(sidenav: MatSidenav) {
    this.sidenav.set(sidenav);
  }

  open({ tpl, config, styles }: { tpl: TemplateRef<any>; config?: SidenavConfig; styles?: string[] }) {
    this.tpl.set(tpl);

    this.sidenav.update((sidenav) => {
      sidenav.position = config?.position || 'start';
      sidenav.disableClose = config?.disableClose ?? false;

      return sidenav;
    });

    this.cssClasses()?.length && this.domSrv.removeClasses('mat-sidenav', this.cssClasses());

    if (styles?.length) {
      this.domSrv.addClasses('mat-sidenav', styles);
      this.cssClasses.set(styles);
    }

    this.sidenav()
      .open()
      .then(() => {
        this.status.set('opened');
        this.domSrv.scrollToTop('.mat-sidenav-inner-container', 'instant');
      });
  }

  close() {
    this.sidenav()
      .close()
      .then(() => {
        this.status.set('closed');
      });
  }
}
