export enum Navigation {
  Login = 'login',
  Register = 'register',
  ForgotPassword = 'forgot_password',
  ResetPassword = 'reset_password',
  Feed = 'feed',
  Explore = 'explore',
  Me = 'me',
  User = 'user',
  Trip = 'trip',
  TripForm = 'trip_form',
  TripFormWithId = 'trip_form_with_id',
  TripsList = 'trips_list',
  Advisor = 'advisor',
  Settings = 'settings',
  Terms = 'terms',
  Privacy = 'privacy',
}

export const Paths = {
  [Navigation.Login]: ['login'],
  [Navigation.Register]: ['register'],
  [Navigation.ForgotPassword]: ['forgot-password'],
  [Navigation.ResetPassword]: ['reset-password'],
  [Navigation.Feed]: ['feed'],
  [Navigation.Explore]: ['explore'],
  [Navigation.Me]: ['me'],
  [Navigation.User]: (id: string = ':id') => ['user', id],
  [Navigation.Trip]: (
    username: string = ':username',
    urlSlugLocation: string = ':urlSlugLocation',
    urlSlugTitle: string = ':urlSlugTitle',
  ) => [username, 'trip', urlSlugLocation, urlSlugTitle],
  [Navigation.TripForm]: ['editor', 'trip'],
  [Navigation.TripFormWithId]: (id: string = ':id') => ['editor', 'trip', id],
  [Navigation.TripsList]: (username: string = ':username', id: string = ':id') => [username, 'list', id],
  [Navigation.Advisor]: ['advisor'],
  [Navigation.Settings]: ['settings'],
  [Navigation.Terms]: ['terms'],
  [Navigation.Privacy]: ['privacy'],
};

export const getNavigationRoutePath = (path: string[], param?: string): string => {
  if (param) {
    return [...path, param].join('/');
  }

  return path.join('/');
};
