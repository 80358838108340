export enum Cookie {
  Token = 'tl_token',
  Lang = 'tl_lang',
}

export enum AlertType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}
