import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { StateService } from '@app/application/services/state/state.service';
import { LAYOUT_BREAKPOINT_MAP, LayoutBreakpoint } from '@app/core/constants/global.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private get layout(): LayoutBreakpoint {
    return this.stateSrv.state.layout();
  }

  constructor(
    private dialog: MatDialog,
    private stateSrv: StateService,
  ) {}

  openDialog<T>(cmp: any, config: MatDialogConfig = {}, data: { [key: string]: any } = {}): Observable<T> {
    const dialogConfig = { ...this.defaultConfig(), ...config, data };
    return this.dialog.open(cmp, dialogConfig).afterClosed();
  }

  getSmDialogConfig(): MatDialogConfig {
    return {
      width: '420px',
      minWidth: this.layout === LayoutBreakpoint.Mobile ? '95%' : '420px',
    };
  }

  getMdDialogConfig(): MatDialogConfig {
    return {
      width: `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.Tablet)}px`,
      minWidth:
        this.layout === LayoutBreakpoint.Mobile ? '95%' : `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.Tablet)}px`,
    };
  }

  getLgDialogConfig(): MatDialogConfig {
    return {
      width: `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.Desktop)}px`,
      minWidth:
        this.layout === LayoutBreakpoint.Mobile ? '95%' : `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.Desktop)}px`,
    };
  }

  getXlDialogConfig(): MatDialogConfig {
    return {
      width: `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.DesktopLg)}px`,
      minWidth:
        this.layout === LayoutBreakpoint.Mobile ? '95%' : `${LAYOUT_BREAKPOINT_MAP.get(LayoutBreakpoint.DesktopLg)}px`,
    };
  }

  getFullScreenDialogConfig(): MatDialogConfig {
    return {
      width: '100dvw',
      height: '100dvh',
      maxWidth: '100dvw',
      maxHeight: '100dvh',
      panelClass: ['tl-ds', 'tl-dialog', 'full-screen', 'backdrop'],
      autoFocus: false,
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
    };
  }

  private defaultConfig(): MatDialogConfig {
    return {
      autoFocus: false,
      disableClose: false,
      panelClass: ['tl-ds', 'tl-dialog'],
      width: '520px',
      minWidth: this.layout === LayoutBreakpoint.Mobile ? '95vw' : '520px',
      maxWidth: this.layout === LayoutBreakpoint.Mobile ? '95vw' : '80vw',
    };
  }
}
