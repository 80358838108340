import { Injectable } from '@angular/core';
import { StateService } from '@app/application/services/state/state.service';
import { Cookie } from '@app/core/enums/app.enums';
import { AuthRes } from '@app/domain/interfaces/auth.interface';
import { User } from '@app/domain/interfaces/user.interfaces';
import { AuthApiService } from '@app/infrastructure/services/auth/auth.service';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { RouterService } from '../router/router.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private stateSrv: StateService,
    private storageSrv: StorageService,
    private routerSrv: RouterService,
    private authApiSrv: AuthApiService,
  ) {}

  init(): Observable<AuthRes> {
    return this.authApiSrv.auth().pipe(
      tap((res) => this.signDataHandler(res)),
      catchError((err) => {
        this.logOut();

        return throwError(() => err);
      }),
    );
  }

  signDataHandler({ user, access_token }: AuthRes) {
    this.stateSrv.update<User>('user', user);
    access_token && this.storageSrv.setCookie(Cookie.Token, access_token, { days: 7 });
  }

  logOut() {
    this.stateSrv.reset();
    this.storageSrv.deleteCookie(Cookie.Token);
    this.routerSrv.navigate([]);
  }
}
