export enum ErrorName {
  ManagedError = 'ManagedError',
}

export class ManagedError extends Error {
  constructor(message: string) {
    super(message);

    this.name = ErrorName.ManagedError;
  }
}
