import { Injectable, computed, signal } from '@angular/core';
import { State } from '@app/application/interfaces/state.interface';
import { DEFAULT_LANG } from '@app/core/constants/global.constants';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private readonly _state = signal<State>({
    isReady: false,
    loader: false,
    lang: DEFAULT_LANG,
    layout: null,
    pageConfig: { header: true, footer: true },
    user: null,
  });

  readonly state = {
    isReady: computed(() => this._state().isReady),
    loader: computed(() => this._state().loader),
    lang: computed(() => this._state().lang),
    layout: computed(() => this._state().layout),
    pageConfig: computed(() => this._state().pageConfig),
    user: computed(() => this._state().user),
  };

  update<T>(key: keyof State, value: T) {
    this._state.update((state) => ({ ...state, [key]: value }));
  }

  reset() {
    this.update('user', null);
  }
}
