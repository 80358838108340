import { inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { State } from '@app/application/interfaces/state.interface';
import { StateService } from '@app/application/services/state/state.service';
import { LayoutBreakpoint } from '@app/core/constants/global.constants';
import { Navigation, Paths } from '@app/core/constants/navigation.constants';
import { AlertType } from '@app/core/enums/app.enums';
import { ErrorService } from '@app/core/services/error/error.service';
import { LanguageService } from '@app/core/services/language/language.service';
import { RouterService } from '@app/core/services/router/router.service';
import { Lang } from '@app/core/types/lang.types';
import { Placeholder } from '@app/presentation/constants/file.constants';
import { DialogService } from '@app/presentation/services/dialog/dialog.service';
import { DomService } from '@app/presentation/services/dom/dom.service';
import { FormsService } from '@app/presentation/services/forms/forms.service';
import { SidenavService } from '@app/presentation/services/sidenav/sidenav.service';
import { ToastService } from '@app/presentation/services/toast/toast.service';
import { HelpersService } from '@app/shared/services/helpers/helpers.service';
import { UtilsService } from '@app/shared/services/utils/utils.service';
import { Observable } from 'rxjs';

export abstract class BaseComponent {
  readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  readonly utilsSrv: UtilsService = inject(UtilsService);
  readonly domSrv: DomService = inject(DomService);
  readonly sidenavSrv: SidenavService = inject(SidenavService);
  readonly formsSrv: FormsService = inject(FormsService);
  readonly helpersSrv: HelpersService = inject(HelpersService);
  readonly errorSrv: ErrorService = inject(ErrorService);

  private readonly stateSrv: StateService = inject(StateService);
  private readonly langSrv: LanguageService = inject(LanguageService);
  private readonly routerSrv: RouterService = inject(RouterService);
  private readonly dialogSrv: DialogService = inject(DialogService);
  private readonly toastSrv: ToastService = inject(ToastService);

  readonly Navigation = Navigation;
  readonly Paths = Paths;
  readonly Placeholder = Placeholder;
  readonly state = this.stateSrv.state;

  get isPlatformBrowser(): boolean {
    return this.utilsSrv.isPlatformBrowser;
  }

  get isMobile(): boolean {
    return this.stateSrv.state.layout() === LayoutBreakpoint.Mobile;
  }

  get isTablet(): boolean {
    return this.stateSrv.state.layout() === LayoutBreakpoint.Tablet;
  }

  get isDesktop(): boolean {
    return ![LayoutBreakpoint.Mobile, LayoutBreakpoint.Tablet].includes(this.stateSrv.state.layout());
  }

  getUrl(key: Navigation): string {
    return `/${this.state.lang()}/${Paths[key]}`;
  }

  getTranslationRaw(path: string, lang?: Lang): Observable<{ [key: string]: any }> {
    return this.langSrv.getRaw(path, lang);
  }

  translate(key: string, params: { [key: string]: any } = {}): string {
    return this.langSrv.translate(key, params);
  }

  changeLanguage(lang: Lang): void {
    this.langSrv.changeLanguage(lang);
  }

  updateState<T>(key: keyof State, value: T) {
    this.stateSrv.update(key, value);
  }

  navigate(url: string[], extras: NavigationExtras = {}) {
    return this.routerSrv.navigate(url, extras);
  }

  navigateWithoutLang(url: string[], extras: NavigationExtras = {}) {
    return this.routerSrv.navigateWithoutLang(url, extras);
  }

  navigateByQueryParams(queryParams: { [key: string]: string } = {}) {
    return this.routerSrv.navigateByQueryParams(queryParams);
  }

  navigateToNewTab(url: string[]) {
    return this.routerSrv.navigateToNewTab(url);
  }

  navigateToUserProfile(username: string) {
    const usernameParam = `@${username}`;

    return this.navigateWithoutLang(Paths[Navigation.User](usernameParam));
  }

  navigateToTrip(username: string, urlSlug: string) {
    const usernameParam = `@${username}`;
    const urlSlugParams = urlSlug.split('/');
    const urlSlugLocation = urlSlugParams[0];
    const urlSlugTitle = urlSlugParams[1];

    return this.navigateWithoutLang(Paths[Navigation.Trip](usernameParam, urlSlugLocation, urlSlugTitle));
  }

  navigateToTripsList(username: string, id: string) {
    const usernameParam = `@${username}`;

    return this.navigateWithoutLang(Paths[Navigation.TripsList](usernameParam, id));
  }

  scrollTo(el: HTMLElement) {
    this.domSrv.scrollTo(el);
  }

  openDialog<T>({
    cmp,
    config,
    data,
  }: {
    cmp: any;
    config?: MatDialogConfig;
    data?: { [key: string]: any };
  }): Observable<T> {
    return this.dialogSrv.openDialog(cmp, config || {}, data || {});
  }

  getDialogWidth(key: 'sm' | 'md' | 'lg' | 'xl' | 'fullScreen'): MatDialogConfig {
    const dialogConfig = {
      sm: this.dialogSrv.getSmDialogConfig(),
      md: this.dialogSrv.getMdDialogConfig(),
      lg: this.dialogSrv.getLgDialogConfig(),
      xl: this.dialogSrv.getXlDialogConfig(),
      fullScreen: this.dialogSrv.getFullScreenDialogConfig(),
    };

    return dialogConfig[key];
  }

  openToast(type: AlertType, msgKey: string, msgParams: { [key: string]: any } = {}) {
    this.toastSrv.openToast(type, this.langSrv.translate(msgKey, msgParams));
  }

  getFormCtrlErrorMsg(ctrl: FormControl): string {
    const errorMsg = this.formsSrv.mapFormFieldErrors(ctrl.errors);

    if (typeof errorMsg === 'string') {
      return this.langSrv.translate(errorMsg);
    } else if (errorMsg?.key && errorMsg?.value) {
      return this.langSrv.translate(errorMsg?.key, errorMsg?.value);
    }

    return '';
  }
}
