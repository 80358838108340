import { Environment } from '@app/core/interfaces/environment.interface';

export const environment: Environment = {
  production: true,
  name: 'prod',
  appUrl: 'https://www.tripilog.com',
  apiUrl: 'https://api.tripilog.com',
  fbPixelId: '1554682738594046',
  googleTagManagerId: 'G-HNDF3JWHK0',
  googleClientId: '905835887438-48ioe7iuhiguk3e1l48o2459e9m44fk4.apps.googleusercontent.com',
  googleMapsKey: 'AIzaSyBi4YxAOURanB844dx84bWU8R46Rk2faFo',
  mapboxglAccessToken: 'pk.eyJ1IjoibmFjb3JnYSIsImEiOiJjbHdhYmt3YnkwYTdrMmpvZTlpZnB4eDVkIn0.y6nj3wPxLooWGNEJwVspuw',
  sentryDsn: 'https://650881e110067a41fc02a9ebc51dbc24@o4507542870163456.ingest.de.sentry.io/4507542871998544',
  timeout: 30000,
};
