import { Lang } from '@app/core/types/lang.types';

export enum LayoutBreakpoint {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Desktop = 'desktop',
  DesktopLg = 'desktop-lg',
  DesktopXl = 'desktop-xl',
}

export const LAYOUT_BREAKPOINT_MAP = new Map<LayoutBreakpoint, number>([
  [LayoutBreakpoint.Mobile, 576],
  [LayoutBreakpoint.Tablet, 768],
  [LayoutBreakpoint.Desktop, 992],
  [LayoutBreakpoint.DesktopLg, 1200],
  [LayoutBreakpoint.DesktopXl, 1400],
]);

export const LANGS_LIST = ['es', 'en'] as Lang[];
export const DEFAULT_LANG: Lang = 'es';
export const LANGUAGES: { key: Lang; label: string }[] = [
  { key: 'es', label: 'spanish' },
  { key: 'en', label: 'english' },
];
