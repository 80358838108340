import { ElementRef, Injectable } from '@angular/core';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  constructor(private utilsSrv: UtilsService) {}

  mapToArray(map: Map<any, any>) {
    return Array.from(map, ([key, value]) => ({ key, ...value }));
  }

  sortByKey(arr: Array<any>, key: string) {
    return arr.sort((a, b) => a[key].localeCompare(b[key]));
  }

  centerX(ev: MouseEvent, scrollContainer: ElementRef, idx: number) {
    const scrollContainerEl = scrollContainer.nativeElement;
    const itemEl = ev.target as HTMLElement;
    this.centerXHandler(scrollContainerEl, itemEl);
  }

  centerXOnInit(scrollContainer: ElementRef, idx: number) {
    const scrollContainerEl = scrollContainer.nativeElement;
    const items = scrollContainerEl.children;

    if (items.length > 0) {
      const itemEl = items[idx];
      !!itemEl && this.centerXHandler(scrollContainerEl, itemEl);
    }
  }

  private centerXHandler(scrollContainerEl, itemEl) {
    if (this.utilsSrv.isPlatformBrowser) {
      const scrollContainerWidth = scrollContainerEl.offsetWidth;
      const itemLeft = itemEl.offsetLeft;
      const itemWidth = itemEl.offsetWidth;

      const scrollTo = itemLeft + itemWidth / 2 - scrollContainerWidth / 2;

      scrollContainerEl.scroll({
        left: scrollTo,
        behavior: 'smooth',
      });
    }
  }
}
